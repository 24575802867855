<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:end>
            <!-- <FileUpload
              mode="basic"
              accept="image/*"
              :maxFileSize="1000000"
              label="Import"
              chooseLabel="Import"
              class="mr-2 inline-block"
            />-->
            <Button label="Exportar" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)" />
          </template>
        </Toolbar>

        <DataTable exportFilename="relatorio[GUIAS_IMPLANTADAS]" csvSeparator=";" ref="dt" :value="naoencontrados"
          v-model:selection="selectedProducts" dataKey="id" :paginator="true" :rows="50" :filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
          responsiveLayout="scroll">
          <template #header>
            <div class="
                flex flex-column
                md:flex-row md:justify-content-between md:align-items-center
              ">
              <h5 class="m-0">Descrição Não Encontradas</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Buscar" />
              </span>
            </div>
          </template>

          <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
          <Column field="id" header="id" :sortable="true" headerStyle="width:14%; min-width:10rem;">
          </Column>

          <Column field="descricao" header="Convertida" :sortable="true" headerStyle="width:30%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">Convertida</span>
              {{ slotProps.data.descricao }}
            </template>
          </Column>
          <Column field="original" header="Original" :sortable="true" headerStyle="width:30%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">Original</span>
              {{ slotProps.data.original }}
            </template>
          </Column>

          <Column header="Vincular" headerStyle=" min-width:25rem;">
            <template #body="slotProps">
              <Dropdown id="id_tipo" filter required="true" v-model="normalizado[slotProps.data.id]" :options="normalizados"
                optionLabel="label" optionValue="value" placeholder="Selecione um tipo"
                @change="vincularPalavra(slotProps.data.id,slotProps.data.descricao)"></Dropdown>
            </template>
          </Column>


        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";

export default {
  data() {
    return {
      normalizado: [],
      token: localStorage.getItem('token'),
      dado_pendente: [
        { label: "Limpar Filtro", value: "" },
        { label: "Contrato RE", value: "1" },
        { label: "Inscrição Imobiliária", value: "2" },
        { label: "Ordem SAP", value: "3" },
        { label: "Endereço", value: "4" },
        { label: "Área", value: "5" },
        { label: "Tipo", value: "6" },
        { label: "Regional", value: "7" },
        { label: "Regra Pagamento", value: "8" },
      ],
      parcelas: [
        { label: "1", value: 1 },
        { label: "2", value: 2 },
        { label: "3", value: 3 },
        { label: "4", value: 4 },
        { label: "5", value: 5 },
        { label: "6", value: 6 },
        { label: "7", value: 7 },
        { label: "8", value: 8 },
        { label: "9", value: 9 },
        { label: "10", value: 10 },
        { label: "11", value: 11 },
        { label: "12", value: 12 },
      ],
      dado: "",
      arquivo: "",
      naoencontrados: [],
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      unidade: {},
      selectedProducts: null,
      filters: {},
      submitted: false,
      status: 0,
      empresas: [],
      areas: [],
      regionais: [],
      tipos: [],
      estados: [],
      cidades: [],
      id_estado: "",
      id_cidade: "",
      id_empresa: "",
      id_unidade: "",
      parcela: "",
      id_tipo: "",
      data_referencia: "",
      data_vencimento: "",
      total: "",
      boleto: "",
      faturas: [],
      normalizados: {},

    };
  },
  productService: null,
  created() {
    this.initFilters();
  },
  mounted() {
    this.listarPalavras();
    this.listarNormalizados();
  },
  methods: {
    vincularPalavra(id, descricao){
   
      let dados = {
        id_classificacao: this.normalizado[id],
        descricao: descricao,
        id: id

      }
      
      
      this.axios
        .post("/dicionario/vincular", dados)
        .then(() => {
          this.listarPalavras();
          this.$toast.add({
            severity: "success",
            summary: "Sucesso!",
            detail: "Palavra Vinculada com sucesso!",
            life: 3000,
          });
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });
    },
    listarPalavras() {
      this.axios
        .post("/dicionario/listar")
        .then((response) => {
          this.naoencontrados = response.data.dados;
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });

    },

    listarNormalizados() {
      this.axios
        .post("/dicionario/normalizados")
        .then((response) => {
          this.normalizados = response.data.dados;

          let options = [];
          let desc = {};
          response.data.dados.map((el) => {

            let option = { value: el["id"], label: el["descricao"] };
            desc[el["id"]] = el["descricao"];
            options.push(option);

          });
          this.normalizados = options;

        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });
    },

    formatCurrency(value) {
      if (value)
        return value.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
      return;
    },
    openNew() {
      this.unidade = {};
      this.submitted = false;
      this.productDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
    onChangeFileUpload() {
      this.arquivo = this.$refs.arquivo.files[0];
    },
    salvarFatura() {
      this.submitted = true;

      let formData = new FormData();
      formData.append("arquivo", this.arquivo);

      let dados = {
        id_empresa: this.id_empresa,
        id_unidade: this.id_unidade,
        id_tipo: this.id_tipo,
        data_referencia: this.data_referencia,
        data_vencimento: this.data_vencimento,
        total: this.total,
        boleto: this.boleto,
        parcela: this.parcela,
      };

      formData.append("dados", JSON.stringify(dados));

      if (this.unidade.id) {
        this.axios
          .post("/naoencontrados/editar", this.unidade)
          .then(() => {
            this.listarFatura();
            this.productDialog = false;
            this.unidade = {};
          })
          .catch(() => {
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      } else {
        this.axios
          .post("/faturas/adicionar", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {
            this.listarFatura();
            this.productDialog = false;
            this.unidade = {};
          })
          .catch(() => {
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      }
    },
    download(fatura) {
      let url = { ...fatura }
      window.open(url.link + "/" + this.token);
    },
    editProduct(fatura) {
      this.productDialog = true;
      this.fatura = { ...fatura };
      this.id_empresa = this.fatura.id_empresa;
      this.id_unidade = this.fatura.id_unidade;
      this.id_tipo = this.fatura.id_tipo;
      this.data_referencia = this.fatura.data_referencia;
      this.data_vencimento = this.fatura.data_vencimento;
      this.total = this.fatura.total;
      this.boleto = this.fatura.boleto;
      this.parcela = this.fatura.parcela;
    },
    apagarFaturaModal(fatura) {
      this.fatura = fatura;
      this.deleteProductDialog = true;
    },
    apagarFatura() {
      this.axios
        .post("/faturas/apagar", this.unidade)
        .then(() => {
          this.listarFatura();
          this.deleteProductDialog = false;
          this.unidade = {};
          this.$toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: "Fatura apagada com sucsso!",
            life: 3000,
          });
        })
        .catch(() => {
          self.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      let obj = this.selectedProducts;
      Object.keys(obj).forEach((key) => {
        this.axios
          .post("/naoencontrados/apagar", obj[key])
          .then(() => {
            this.listarFatura();
            this.deleteProductDialog = false;
            this.unidade = {};
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: "Usário apagado com sucesso!s",
              life: 3000,
            });
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      });

      this.deleteProductsDialog = false;
      this.selectedProducts = null;
      this.$toast.add({
        severity: "success",
        summary: "Sucesso",
        detail: "Faturas apagada com sucesso!",
        life: 3000,
      });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/demo/badges.scss";
</style>
